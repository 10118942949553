import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/index'
const routes = [
  {
    path: '/',
    name: '',
    redirect: '/login'
  },
  {
    path: '/Login',
    component: () => import('../views/login'),
    name: 'login',
    meta: { title: '登陆' }
  },
  {
    path: '/preExamination',
    component: () => import('../views/preExamination'),
    name: 'preExamination',
    meta: { title: '介绍模拟考' } 
  },
  {
    path: '/examHome',
    component: () => import('../views/examHome'),
    name: 'examHome',
    meta: { title: '查看历史、购买模拟考等操作' } 
  },
  // {
  //   path: '/examEnter',
  //   component: () => import('../views/examEnter'),
  //   name: 'examEnter',
  //   meta: { title: '查看历史、购买模拟考等操作' } 
  // },
  ,
  // {
  //   path: '/emptyNotification',
  //   component: () => import('../views/emptyNotification'),
  //   name: 'emptyNotification',
  //   meta: { title: '错误提示页面' } 
  // },    
  {
    path: '/getScore',
    component: () => import('../views/getScore'),
    name: 'getScore',
    meta: { title: '获取分数详情页面' } 
  },    
  {
  path: '/getUnCompleteExamList',
  component: () => import('../views/getUnCompleteExamList'),
  name: 'getUnCompleteExamList',
  meta: { title: '获取分数详情页面' } 
  },
  {
    path: '/getScoreList',
    component: () => import('../views/getScoreList'),
    name: 'getScoreList',
    meta: { title: '获取分数列表页' } 
  },
  {
    path: '/record',
    component: () => import('../views/record'),
    name: 'record',
    meta: { title: '测试页面' }
  },


  {

    path: '/examination',
    component: () => import('../views/examination'),
    meta: { title: '模拟考试页面' }
},




{
  path: '/',
  name: 'HelloWorldTest',
  component: Index,
  path: '/HelloWorldTest',
  component: () => import('../views/HelloWorldTest'),
  meta: { title: '测试页面' }
},
{
  path: '/',
  name: 'questionListPage',
  component: Index,
  path: '/questionListPage',
  component: () => import('../views/questionListPage'),
  meta: { title: '测试页面' }
},
{
  path: '/',
  name: 'note',
  meta: { title: '收藏单词' },
  children: [{
    component: () => import('../views/note'),
    path: 'note',
    name: 'note',
    meta: { title: '' }
  }]
},
{
  path: '/wordListPage',
  name: 'wordListPage',
  meta: { title: '单词本' },

    component: () => import('../views/wordListPage'),


  
},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
