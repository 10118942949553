<template>
  <div class="index">
    <div class="header">
      <div class="header-menu">
        <el-menu router :default-active="activeIndex" class="el-menu-demo" mode="horizontal" active-text-color="#3370ff" text-color='#646a73'>
          <img class="header-menu-logo" src="../assets/index/logo.png" alt="">
          <span class="header-menu-text">苏肥鸭多邻国</span>
          <el-menu-item @click="nav2questionList()" index="practise">真题题库</el-menu-item>
          <el-menu-item index="note">单词本</el-menu-item>
          <img class="header-menu-icon" src="../assets/index/logo.png" alt="">
          <div class="header-menu-name">VIP到期时间:{{VipDeadline}}</div>
          <div class="header-menu-name">&nbsp;&nbsp;&nbsp;{{username}}</div>
          <div class="header-menu-out" @click="out">退出</div>
        </el-menu>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>
<script>
import { logout } from '../api/login'
export default {
  name: 'index',
  data () {
    return {
      activeIndex: 'practise',
      username: ''
    }
  },
  methods: {
    nav2questionList(e){
      false
      //console.log(123)
      // this.$router.push('/login')
    },
    out () {
      logout().then(res => {
        this.$router.push('/login')
      })
    },
    init () {
      this.username = localStorage.getItem('dlgUsername')
      this.VipDeadline = localStorage.getItem('VipDeadline')
      this.activeIndex = this.$route.name
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss">
.el-menu-item {
  font-size: 16px !important;
}
.el-menu-item:not(.is-disabled):hover {
  background-color: #fff !important;
}
</style>
<style lang="scss" scoped>
.index {
  background-color: #F7F9FD;
  display: flex;
  justify-content: center;
  .header {
    background-color: #fff;
    width: 100%;
    z-index: 10;
    height: 60px;
    position: fixed;
    box-shadow: 0px 2px 10px 2px rgba(227, 229, 235, 0.4),
      0px 4px 15px 0px rgba(180, 187, 231, 0.2);
    display: flex;
    justify-content: center;
    &-menu {
      width: 1200px;
      &-icon {
        margin: 20px 0 0 0;
        margin-left: auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
      &-name {
        line-height: 58px;
        font-size: 14px;
      }
      &-logo {
        margin-top: 10px;
        width: 40px;
        height: 40px;
      }
      &-text {
        color: #646a73;
        line-height: 56px;
        font-size: 16px;
        margin-right: 30px;
      }
      &-out{
        cursor: pointer;
        color: #3370ff;
        margin: 0 0 0 20px;
        line-height: 58px;
        font-size: 14px;
      }
    }
  }
  .content {
    padding:80px 0 32px 0;
    width: 1200px;
  }
}
</style>
