import CryptoJS from 'crypto-js'
const key = '6423818282854273'; // 32位
const txt = '我们是不是实现加密了 哈哈哈哈';
// 加密

export default {
     encrypt(text) {
        return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString()
    },

    // 解密
     decrypt(text) {
        let decrypted = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8)
    }
}

