import axios from 'axios'
import { JSEncrypt } from 'jsencrypt'
import { ElMessage, ElMessageBox } from 'element-plus'
import { createRouter } from "vue-router";
import router from '../router/index.js'

import CryptoJS from './AESED.js';

// 解密
export function decryptedData (txt) {
  var privateKey = `
  MIIBVQIBADANBgkqhkiG9w0BAQEFAASCAT8wggE7AgEAAkEAtfitbXNZSXnETlRD
  JpmDqk0YwPS4H6pzuE5sN7cE1ZoYZ3ugu4eMBN/Q2VA+muU2r53/c3HsP5VhRxGr
  Ys5jkwIDAQABAkBIaVeh6TfLjD/4p4E9N/Nz7k/+YKNwPmTkH5jiptquoIFyHQLf
  ZN6IVSlWnnrQdjuT4XSDzl3CvK7Udsgy1PNxAiEA8I5UxLQTtMV+vt0hDSlTuN7c
  szJTDRzWmiDhlvLMypsCIQDBp3oIiswothdNxMhkbiHUki97dWJGV/SGzTxunTV+
  aQIga6N0gNQHIVNh5Gu0F1yPruBiJvj0uqBV0n+2EcIxKtMCIQCJd5N/INgQRkKA
  qCIAeNV3Cla+cvNSL/ma/3HYq6xfkQIhAK3BwR0F5RW3ki68T0FxvJOVFqIp1IBv
  tT2LXzUy7CT2
`
  // var privateKey = 'BwIAAACkAABSU0EyAAIAAAEAAQCT+ANbDp2lvCXBKszQFeYFFK2r77t8u/fNzeerFvcqDIxAoTi2/QHn4E/dJsFIWuUjMzGlZBoD623AvMKOoUzpQxeNH5xahUizIC8cmcQVyWEIy/U6U2XYwFqzkcm9CvZxPIPnxXkKnp2BwM8J4LIirGfmgSXfUDT3k5Lqzty98tFOT2iPhDOV7I5y7OgCj3nXZFZ1rW0OWl6XLYG9mZ6nceOhhar0COUNMz9C88Kw1toupz5ylmbKxTdbT4Wh0E2jmtVURiZSGVqbvGs/X0Jj3xnverM2mY6pMPRchMyk5+FEhw1dmshLddTENhwas4vKq/jtSATYnXBchU5q9/QPDvp/hpKLsQAVOq6trDpE8DvmibJpCSjjd4CodAl2pEs='
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

function encryptedData (data) {
  // 公钥
  // var publicKey = `-----BEGIN PUBLIC KEY-----
  // MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCmkcLS2qjpSK538a7HzQH3NvYYmAfM2eLyxwk54lCqWfxXwZVkQJixQiWdzL8vHC4ChfCXOlyG33zDjF/l0L1fjTxWSK+XnnBC9i5MQSI8pEbZWjAtXFmOAQT5V1CVs7ax8rsAjj79zJ8m9vHi48/VA3gbIqi1DNxAlE/UHPhCLQIDAQAB
  // -----END PUBLIC KEY-----
  // `
//   var publicKey = `-----BEGIN PUBLIC KEY-----
// MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCmkcLS2qjpSK538a7HzQH3NvYYmAfM2eLyxwk54lCqWfxXwZVkQJixQiWdzL8vHC4ChfCXOlyG33zDjF/l0L1fjTxWSK+XnnBC9i5MQSI8pEbZWjAtXFmOAQT5V1CVs7ax8rsAjj79zJ8m9vHi48/VA3gbIqi1DNxAlE/UHPhCLQIDAQAB
// -----END PUBLIC KEY-----
//   `
  var publicKey = `
MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBALX4rW1zWUl5xE5UQyaZg6pNGMD0uB+q
c7hObDe3BNWaGGd7oLuHjATf0NlQPprlNq+d/3Nx7D+VYUcRq2LOY5MCAwEAAQ==
`
  // var publicKey = 'BgIAAACkAABSU0ExAAIAAAEAAQCT+ANbDp2lvCXBKszQFeYFFK2r77t8u/fNzeerFvcqDIxAoTi2/QHn4E/dJsFIWuUjMzGlZBoD623AvMKOoUzp'
  // 新建JSEncrypt对象
  const encryptor = new JSEncrypt()
  // 设置公钥
  encryptor.setPublicKey(publicKey)
  // 加密数据
  return encryptor.encrypt(data)
}
function generatorService (baseURL) {
  const service = axios.create({
    // baseURL: baseURL,
    baseURL: baseURL,
    timeout: 500000 // request timeout
  })
  // service.defaults.withCredentials = true
  service.interceptors.request.use(
    config => {
      // config.data = encryptedData(config.data)
      //console.log(encryptedData(config.data))
      //console.log(decryptedData(config.data))

      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
  
  service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            try {
              response.data = JSON.parse(CryptoJS.decrypt(response.data))
          }
          catch(err) {
              try {
                response.data = CryptoJS.decrypt(response.data)
            }
            catch(err) {
                
            }       
          }
          // console.log(response.data )
          if(response.data.code == 2){
            ElMessageBox.alert(response.data.message, '提示', {
              // if you want to disable its autofocus
              // autofocus: false,
              confirmButtonText: 'OK',
              callback: false ,
            })
            // this.$message({
            //   message: res.data.message,
            //   type: 'success'
            // })

          }
          if(response.data.code == -2){
            
            

            ElMessageBox.alert(response.data.message, '提示', {
              // if you want to disable its autofocus
              // autofocus: false,
              confirmButtonText: 'OK',
              callback:console.log(123),
            })
            router.push('login')
            // this.$message({
            //   message: res.data.message,
            //   type: 'success'
            // })

          }

          //console.log(response)
        }
      return response.data
    },
    error => {
      try {
        const status = error.response.status
        // 登录过期
        if (status === 401) {
          //console.log()
        }
        // 服务端异常
      } catch (e) {
        //console.log('捕获Response异常:', e)
      }
      return Promise.reject(error)
    }
  )
  // alert("登录过期")
  return service
}
const service = generatorService('https://www.duolinguo.info/api/')
// const service = generatorService('http://127.0.0.1')
// const service = generatorService('http://Your response" maxlength="5000".0.1')
// service.config['transformRequest'] = function (data, headers) {
//   // 对 data 进行任意转换处理
//   //console.log(data)
//   return data
// }
export default service
