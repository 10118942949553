import request from './request'

export function login (data) {
  return request({
    url: '/login/',
    method: 'post',
    data
  })
}
export function webLogin (data) {
  return request({
    url: '/weblogin',
    method: 'post',
    data
  })
}
export function webLoginCheck (data) {   //轮询查询
  return request({
    url: '/weblogin',
    method: 'post',
    data
  })
}
export function register (data) {
  return request({
    url: '/register/',
    method: 'post',
    data
  })
}
export function send (data) {
  return request({
    url: '/send_sms/',
    method: 'post',
    data
  })
}
export function logout () {
  return request({
    url: '/logout/',
    method: 'get'
  })
}

