<template>
	<div>
		<div class="header">
			<div class="logo">
				<img src="../src/assets/logo.png" alt="">
			</div>
			
			<div v-for="(item,index) in router" v-bind:key="index" class="routerList" @click="routerEvent(index)">
				<span>{{item.name}}</span>
				<div class="line" v-if="item.active"></div>
			</div>
			<!-- <div class="search">
				<img src="../src/assets/search.png" alt="">
				<input type="text">
			</div> -->
			
			<!-- <div class="vip">
				<img src="../src/assets/vip.png" alt="">
				<span>充值VIP</span>
			</div> -->
			<div class="user">
				<img src="https://duolinguosite-1313001975.cos.ap-shanghai.myqcloud.com/assets/wechatIcon/images/default.png" alt="">
				<span>{{userName}}</span>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		computed: {
			num() {
			return this.a + this.b;
			},
			userName() {
			
			return localStorage.getItem('dlgUsername');
			},
		},
		data() {
			return {
				a:"12312",
				router:[
					{
						name:'练习题库',
						active:false,
            path:'questionListPage'
					},
					// {
					// 	name:'苏肥鸭模考',
					// 	active:false
					// },
					{
						name:'单词本',
						active:false,
						path:'wordListPage'
					},
					{
						name:'模拟考试(测试版)',
						active:false,
						path:'examHome'
					},
					// {
					// 	name:'直播课',
					// 	active:false
					// },
					// {
					// 	name:'小程序',
					// 	active:false
					// },
					// {
					// 	name:'联系我们',
					// 	active:false
					// },
					// {
					// 	name:'公司介绍',
					// 	active:false
					// }
				]
			}
		},
		methods:{
			routerEvent(index){
				this.router.forEach(item => {
					item.active = false;
				})
				this.router[index].active = true;
        this.$router.push(this.router[index].path)
			//console.log(1234)
			}
		}
	}
</script>

<style lang="scss">
	@function conversion($num) {
		@return $num/192;
	}

	* {
		margin: 0;
		padding: 0;
	}

	.header {
		height: conversion(10) + rem;
		background: #FFFFFF;
	}

	.logo {
		width: conversion(181) + rem;
		height: conversion(42) + rem;
		float: left;
		margin-left: conversion(240) + rem;
		margin-top: conversion(29) + rem;
	}

	.logo>img {
		width: 100%;
		height: 100%;
	}

	.routerList {
		min-width: conversion(80) + rem;
		height: conversion(100) + rem;
		line-height: conversion(100) + rem;
		margin-left: conversion(28) + rem;
		float: left;
		position: relative;
		font-size: conversion(20) + rem;
		font-weight: 400;
		text-align: center;
		color: #333333;
		cursor: pointer;
	}
	
	.line{
		width: conversion(80) + rem;
		height: conversion(4) + rem;
		background: #FFAA02;
		border-radius: conversion(2) + rem;
		position: absolute;
		bottom:0;
		left: 0;
		right: 0;
		margin: auto;
	}
	.search{
		width: conversion(160) + rem;
		height: conversion(36) + rem;
		background: rgba(216,216,216,0.5);
		border-radius: conversion(20) + rem;
		float:left;
		margin-left: conversion(30) + rem;
		margin-top: conversion(32) + rem;
		position: relative;
	}
	.search > img{
		width: conversion(19) + rem;
		height: conversion(20) + rem;
		position: absolute;
		left:conversion(11) + rem;
		top:0;
		bottom:0;
		margin: auto;
	}
	.search > input{
		width: 100%;
		height: 100%;
		background: transparent;
		border: none;
		outline: none;
		padding-left: conversion(40) + rem;
		padding-right: conversion(20) + rem;
		box-sizing: border-box;
	}
	.vip{
		float: left;
		margin-left: conversion(30) + rem;
		margin-top: conversion(31) + rem;
	}
	.vip > img{
		width: conversion(38)+rem;
		height: conversion(38) +rem;
		display: inline-block;
		vertical-align: middle;
	}
	.vip > span{
		display: inline-block;
		vertical-align: middle;
		font-size: conversion(20) + rem;
		font-weight: 400;
		color: #333333;
		margin-left: conversion(10) +rem;
	}
	.user{
		float: right;
		margin-right: conversion(280) + rem;
		margin-left: conversion(30) + rem;
		margin-top: conversion(31) + rem;
	}
	.user > img{
		width: conversion(38)+rem;
		height: conversion(38) +rem;
		display: inline-block;
		vertical-align: middle;
	}
	.user > span{
		display: inline-block;
		vertical-align: middle;
		font-size: conversion(20) + rem;
		font-weight: 400;
		color: #333333;
		margin-left: conversion(10) +rem;
	}
	body {
		background: #f8f8f8;
	}
</style>
